import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Footer from '../components/Footer1/Footer';
import Headers from '../components/Header102/Header';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          companyName
        }
      }
    }
  `);

  // サイトのメタデータから会社名を抽出
  const companyName = data.site.siteMetadata.companyName;

  return (
    <div>
      <Headers
        title={companyName}
        items={[
          {
            id: 1,
            name: '日本一周',
            link: '/articles/init/',
            dropdown: false,
          },
        ]}
      />
      {children}
      <Footer
        items={[{ name: '日本一周', link: '/articles/init/' }]}
        copyright={companyName}
      />
    </div>
  );
};

export default Layout;
